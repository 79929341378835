<template>
  <div class="AIassessment">
    <h3>检查结论</h3>
    <div class="aijl">
      <div class="aijl-a">
        <h3>AI评估结论</h3>
        <p>右眼</p>
        <p>左眼</p>
      </div>
      <div class="aijl-b">
        <p>屈光检查结论:</p>
        <el-input class="aijl-b-sr" v-model="input" placeholder="请输入内容"></el-input>
        <el-input class="aijl-b-sr" v-model="input" placeholder="请输入内容"></el-input>
      </div>
      <div class="aijl-b">
        <p>眼部检查结论:</p>
        <el-input class="aijl-b-sr" v-model="input" placeholder="请输入内容"></el-input>
        <el-input class="aijl-b-sr" v-model="input" placeholder="请输入内容"></el-input>
      </div>
      <div class="aijl-b">
        <p>眼位:</p>
        <el-input style="width: 34vw" class="aijl-b-sr" v-model="input" placeholder="请输入内容"></el-input>
      </div>
      <div class="aijl-b">
        <p>视功能检查结论:</p>
        <el-input style="width: 34vw" class="aijl-b-sr" v-model="input" placeholder="请输入内容"></el-input>
      </div>
    </div>
    <div class="aijl">
      <h3 style="padding-top: 20px"><span style="padding-right: 0.2rem">AI处理建议</span><i class="el-icon-refresh-right"></i></h3>
      <el-input
          class="aicljy"
          type="textarea"
          :autosize="{ minRows: 6, maxRows: 8}"
          placeholder="请输入内容"
          v-model="input">
      </el-input>
      <h3 style="padding-top: 20px"><span style="padding-right: 0.2rem">诊断处方</span> <i class="el-icon-sort
"></i> </h3>
      <el-input
          style="margin-bottom: 0.3rem"
          class="aicljy"
          type="textarea"
          :autosize="{ minRows: 6, maxRows: 8}"
          placeholder="请输入内容"
          v-model="input">
      </el-input>
    </div>
    <div class="aitjfa">
      <h3 style="padding-top: 20px">AI推荐方案</h3>
      <div class="aitjfa-a">
        <div class="aitjfa-a-t">
          <p>框架矫正：</p>
          <p></p>
        </div>
        <el-button style="height: 3em;" type="primary" size="small">方案确认</el-button>
      </div>
      <div class="aitjfa-a">
        <div class="aitjfa-a-t">
          <p>训练方案：</p>
          <p></p>
        </div>
        <el-button style="height: 3em;" type="primary" size="small">方案确认</el-button>
      </div>
      <div class="aitjfa-a">
        <div class="aitjfa-a-t">
          <p>角膜接触镜矫正：</p>
          <p></p>
        </div>
        <el-button style="height: 3em;" type="primary" size="small">方案确认</el-button>
      </div>
      <div class="aitjfa-a">
        <div class="aitjfa-a-t">
          <p>其他：</p>
          <p></p>
        </div>
        <el-button style="height: 3em;" type="primary" size="small">预约挂号</el-button>
      </div>
    </div>
    <div class="bottoms">
      <p style="padding-left: 0;">复查时间</p>
      <el-date-picker
          v-model="sj"
          type="date"
          placeholder="选择日期">
      </el-date-picker>
      <p>备注</p>
      <el-input class="bottoms-sr2" v-model="input" placeholder="请输入内容"></el-input>
    </div>
  </div>
</template>

<script>
export default {
  name: "AIassessment",
  data() {
    return {
      input: '',
      sj: ''
    }
  },
  created() {

  }
}
</script>

<style scoped lang="scss">
  .AIassessment {
    width: 90%;
    margin: 0 auto;
  }
  .aijl {
    text-align: left;
    .aijl-a {
      display: flex;
      justify-content: start;
      align-items: center;
      p {
        width: 18vw;
        text-align: center;
        &:nth-child(3) {padding-left: 2vw;}
      }
    }
    .aijl-b {
      margin: 10px 0;
      display: flex;
      align-items: center;
      p {width: 8em}
      .aijl-b-sr {
        width: 15vw;
        margin: 0 2vw;
      }
    }
    border-bottom: 1px solid #000000;
    h3 {
      padding: 0;
      padding-top: 0.2rem;
    }
  }
  .aitjfa {
    text-align: left;
    border-bottom: 1px solid #000000;
    .aitjfa-a {
      display: flex;
      overflow: hidden;
      justify-content: space-between;
      margin: 20px 0;
      .aitjfa-a-t {
        display: flex;
        justify-content: start;
        p:nth-child(1) {width: 9em;}
        p:nth-child(2) {max-width: 55vw;}
      }
    }
  }
  .bottoms {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 20px 0;
    .bottoms-sr {width: 15vw}
    .bottoms-sr2 {width: 40vw}
    p {padding: 0 20px;}
  }
  h3 {
    padding-bottom: 0.4rem;
  }
</style>